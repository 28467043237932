import UrlPattern from 'url-pattern';

import { getCurrentMatch } from '../../common/router/router-selectors';
import { getIsLoading } from '../selectors/is-loading-selectors';
import { showGlobalLoader, hideGlobalLoader } from '../actions/global-loader-actions';
import { NAVIGATE_WITHIN_FORUM } from '../../common/actions/navigate-within-forum';
import {
  ROUTE_CATEGORY,
  ROUTE_POST,
  ROUTE_SEARCH,
} from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import { SET_ROUTER_MATCH } from '../../common/router';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { EXPERIMENT_INNER_NAVIGATION } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

const POLLING_TIME = 50;
const MINIMUM_LOADER_TIME = 500;
const MAXIMUM_LOADER_TIME = 2000;
/*
  When path changes (inner navigation happens) we show a global loader for MINIMUM_LOADER_TIME to
  prevent weird scroll and flashing issues. This ensures more smooth transition between pages.

  When going to search page or within search page we don't show the global loader;
  When navigating inside post (pagination) we don't show the loader.
*/
let isVisible;
export default function globalLoaderMiddleware() {
  return store => next => action => {
    const state = store.getState();
    const currentMatch = getCurrentMatch(state);

    if (action.type === NAVIGATE_WITHIN_FORUM) {
      // CASE 1
      // triggered by clicking internal forum links.
      go(action);
    }
    if (isVisible === false && action.type === SET_ROUTER_MATCH) {
      // CASE 2
      // if CASE 1 has already happened, but is currently not in progress and we get SET_ROUTER_MATCH - it means that navigation
      // is triggered externally (browser forward/back).
      // Why not use this for everything and drop CASE 1? Because it's slower = CASE 1 when internal nav happens is fired ~100ms earlier.
      // go(action); // turned off as not sure if it provides better experience
    }

    function go(action) {
      const path = action.payload.path || action.payload.pathname;

      const searchMatch = new UrlPattern(ROUTE_SEARCH).match(path);
      const postMatch = new UrlPattern(ROUTE_POST).match(path);
      const categoryMatch = new UrlPattern(ROUTE_CATEGORY).match(path);
      if (searchMatch) {
        return next(action);
      }

      if (
        !categoryMatch &&
        postMatch &&
        ((postMatch.page !== undefined && !postMatch.deepLinkData) || // going to n-th page
          (currentMatch.route === ROUTE_POST &&
            currentMatch.params.page &&
            postMatch &&
            postMatch.page === undefined)) // going from n-th to 1st
      ) {
        return next(action);
      }

      const isInnerNavigationExperimentEnabled = isExperimentEnabled(
        state,
        EXPERIMENT_INNER_NAVIGATION,
      );

      if (path !== currentMatch.pathname && !isInnerNavigationExperimentEnabled) {
        store.dispatch(showGlobalLoader());
        isVisible = true;
        setTimeout(() => hideGlobalLoaderIfCan(store, currentMatch), MINIMUM_LOADER_TIME);
      }
    }

    return next(action);
  };
}

function hideGlobalLoaderIfCan(store, oldMatch, countNumber = 0) {
  const state = store.getState();

  const isLoading = getIsLoading(state);
  const currentMatch = getCurrentMatch(state);

  const hasRouterMatchedNewRoute = currentMatch.pathname === oldMatch.pathname;
  const hasMaximumLouderTimeBeenReached =
    countNumber < Math.ceil((MAXIMUM_LOADER_TIME - MINIMUM_LOADER_TIME) / POLLING_TIME);
  if (
    hasMaximumLouderTimeBeenReached && // this is to prevent infinite loader if there is some bug
    (hasRouterMatchedNewRoute || isEntityLoadingInProgress(isLoading, currentMatch))
  ) {
    setTimeout(() => hideGlobalLoaderIfCan(store, oldMatch, countNumber + 1), POLLING_TIME);
  } else {
    isVisible = false;
    store.dispatch(hideGlobalLoader());
  }
}

function isEntityLoadingInProgress(isLoading, currentMatch) {
  if (currentMatch.route === ROUTE_CATEGORY) {
    return [
      ...Object.values(isLoading.posts || {}),
      ...Object.values(isLoading.category || {}),
    ].includes(true);
  } else if (currentMatch.route === ROUTE_POST) {
    return Object.values(isLoading.post || {}).includes(true);
  } else {
    return false;
  }
}
